import { PUB_PATH, DATA_PATH } from 'utilities/globals';
import { fetch, fetchPost, buildQuery } from 'utilities/utilities';
// import { dataActions } from 'store/actions.js';
// import { toast } from 'react-toastify';

/* Countries calls */
export function getCountryList ({ full = false, skipNone = false, type = 'consumer' }) {
	const query = {
		full,
		skipNone,
		type
	};
	const queryStr = buildQuery(query, true);
	return fetch(`${PUB_PATH}/countries/list${queryStr}`);
};

export function getInactiveCountryList ({ type = 'consumer' }) {
	const query = {
		type
	};
	const queryStr = buildQuery(query, true);
	return fetch(`${PUB_PATH}/countries/list/inactive${queryStr}`);
};

export function addCountryNotification (values) {
	return fetchPost(`${PUB_PATH}/countries/notification`, values);
};

/* Languages Calls */
export function getLanguageList () {
	return fetch(`${PUB_PATH}/languages/list`);
};

/* Permissions calls */
export function getPermissionsList () {
	return fetch(`${DATA_PATH}/business/permissions`);
};

/* Contact types calls */

export function getBuisnessContactTypes () {
	return fetch(`${DATA_PATH}/business/contactTypes`);
};
