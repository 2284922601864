import { configureStore } from '@reduxjs/toolkit';
import user from './slices/user.js';
import data from './slices/data.js';
import components from './slices/components.js';
import business from './slices/business.js';


export default configureStore({
	reducer: {
		user,
		data,
		components,
		business
	} 
});
