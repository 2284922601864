import { fetch, fetchPost, fetchPut, buildQuery } from 'utilities/utilities';
import { DATA_PATH, PUB_PATH } from 'utilities/globals';

export function createNewBusiness (vals) {
	return fetchPost(`${DATA_PATH}/business/create/`, vals);
};

export function getBusinessDetails (businessId) {
	return fetch(`${PUB_PATH}/business/${businessId}`);
};

export function getMonthsAvailable (businessId) {
	return fetch(`${DATA_PATH}/business/${businessId}/months`);
};

/* BUSINESS CATEGORIES */
export function getBusinessCategoriesList (businessId, activeOnly) {
	let query = activeOnly ? '?active=true' : '';
	return fetch(`${DATA_PATH}/business/${businessId}/categories${query}`);
};

export function submitUpdateBusinessCategory (businessId, data) {
	return fetchPost(`${DATA_PATH}/business/${businessId}/categories/update`, data);
};

export function submitCreateBusinessCategory (businessId, data) {
	return fetchPost(`${DATA_PATH}/business/${businessId}/categories/create`, data);
};

/* BUSINESS ROLES */
export function getBusinessRoleTemplates () {
	return fetch(`${DATA_PATH}/business/roles/templates`);
};

export function getCurrentBusinessRoles (businessId) {
	return fetch(`${DATA_PATH}/business/${businessId}/roles`);
};

export function getOtherBusinessRoles (businessId) {
	return fetch(`${DATA_PATH}/business/${businessId}/other/roles`);
};

export function submitImportRole (businessId, data) {
	return fetchPost(`${DATA_PATH}/business/${businessId}/roles/import`, data);
};

export function submitCreateRole (businessId, data) {
	return fetchPost(`${DATA_PATH}/business/${businessId}/roles/create`, data);
};

export function submitUpdateRole (businessId, data) {
	return fetchPost(`${DATA_PATH}/business/${businessId}/roles/update`, data);
};

export function submitDeleteRole (businessId, roleId) {
	return fetchPost(`${DATA_PATH}/business/${businessId}/roles/delete/${roleId}`);
};

/* MANAGE BUSINESS ACCOUNT */

export function getBusinessManageDetails (businessId) {
	return fetch(`${DATA_PATH}/business/${businessId}/manage/details`);
};

export function submitUpdateBusinessField (businessId, field, value) {
	return fetchPut(`${DATA_PATH}/business/${businessId}/manage`, {[field]: value});
};

export function submitUpdateBusinessLogo (businessId, data) {
	return fetchPut(`${DATA_PATH}/business/${businessId}/manage/logo`, data);
};


/* BUSINESS CONTACTS */
export function getContactTypes () {
	return fetch(`${DATA_PATH}/business/contactTypes`);
};

export function getBusinessContacts (businessId) {
	return fetch(`${DATA_PATH}/business/${businessId}/manage/contacts`);
};

export function addBusinessContact (businessId, data) {
	return fetchPost(`${DATA_PATH}/business/${businessId}/manage/contacts/add`, data);
};

export function removeBusinessContact (businessId, data) {
	return fetchPost(`${DATA_PATH}/business/${businessId}/manage/contacts/remove`, data);
};

/* BUSINESS REFUNDS */

export function getPurchaseDetails (transactionId) {
	return fetchPost(`${DATA_PATH}/business/transactions/details`, {transactionId});
};

export function refundPurchase (data) {
	return fetchPost(`${DATA_PATH}/business/transactions/refund`, data);
};

/* TIP ME */

export function doSubmitTip (data) {
	return fetchPost(`${DATA_PATH}/business/tipme/complete`, data);
};

export function getPollDetails (businessId, pollId) {
	return fetch(`${PUB_PATH}/business/${businessId}/polls/${pollId}`);
};

export function getPollList (businessId) {
	return fetch(`${PUB_PATH}/business/${businessId}/polls/list`);
};

export function doPollVote (data) {
	return fetchPost(`${DATA_PATH}/business/polls/vote`, data);
};

export function checkHandleAvailable (handle) {
	let query = buildQuery({handle}, true);
	return fetch(`${DATA_PATH}/business/handle/checkAvailable/${query}`)
};
