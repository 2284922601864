import { createSlice } from '@reduxjs/toolkit'

export const login = createSlice({
	name: 'login',
	initialState: {
		success: false,
		error: {
			username: null,
			password: null
		},
		show: false
	},
	reducers: {
		loginSuccess: (state, action) => {
				state.success = true
		},
		loginError: (state, action) => {
			state.success = false;
			state.error = action.payload;
		},
		showLogin: (state) => {
			state.show = true
		},
		hideLogin: (state) => {
			state.show = false
		}
	}
})

export const {
	loginSuccess,
	loginError,
	showLogin,
	hideLogin
} = login.actions

export default login.reducer
