import {
	openConfirmDialog,
	closeConfirmDialog,
	openCreditLimitDialog,
	closeCreditLimitDialog,
	openLoginModal,
	closeLoginModal,
	openBuyCreditsModal,
	closeBuyCreditsModal
} from './slices/components.js';

import {
	saveCountries,
	saveLanguages,
	saveReferrer,
	saveBusiness,
	loadingStart,
	loadingEnd,
	saveUserMenu,
	savePermissions,
	saveContactTypes
} from './slices/data.js';

import {
	loginSuccess,
	loginError,
	showLogin,
	hideLogin
} from './slices/login.js';

import {
	setData,
	refreshData,
	refreshBalance,
	removeData,
	logout,
	passwordUpdate,
	updateFavorites,
	setAutoPays,
	setUserBusinessNav,
	selectBusinessNav,
	updateUserProfileItem,
	setUserReports
} from './slices/user.js';

import {
	setBusinessName,
    saveCategoryList,
    saveBusinessRoles,
    saveBusinessRoleTemplates,
    saveBusinessOtherRoles,
    saveBusinessAccount,
    updateBusinessName,
    updateBusinessPaypal,
    updateBusinessDefaultPrice,
    updateBusinessLanguages,
    updateBusinessCountry,
	updateBusinessHandle,
    saveBusinessContacts,
    saveBusinessEmployeesList,
    saveBusinessEmployeeInvitesList,
    saveBusinessTags,
    addBusinessTags,
    removeBusinessTags
} from './slices/business.js';

export const componentActions = {
	openConfirmDialog,
	closeConfirmDialog,
	openCreditLimitDialog,
	closeCreditLimitDialog,
	openLoginModal,
	closeLoginModal,
	openBuyCreditsModal,
	closeBuyCreditsModal
};

export const dataActions = {
	saveCountries,
	saveLanguages,
	saveReferrer,
	saveBusiness,
	loadingStart,
	loadingEnd,
	saveUserMenu,
	savePermissions,
	saveContactTypes
};

export const loginActions = {
	loginSuccess,
	loginError,
	showLogin,
	hideLogin
};

export const userActions = {
	setData,
	refreshData,
	refreshBalance,
	removeData,
	logout,
	passwordUpdate,
	updateFavorites,
	setAutoPays,
	setUserBusinessNav,
	selectBusinessNav,
	updateUserProfileItem,
	setUserReports
};

export const businessActions = {
	setBusinessName,
    saveCategoryList,
    saveBusinessRoles,
    saveBusinessRoleTemplates,
    saveBusinessOtherRoles,
    saveBusinessAccount,
    updateBusinessName,
    updateBusinessPaypal,
    updateBusinessDefaultPrice,
    updateBusinessLanguages,
    updateBusinessCountry,
	updateBusinessHandle,
    saveBusinessContacts,
    saveBusinessEmployeesList,
    saveBusinessEmployeeInvitesList,
    saveBusinessTags,
    addBusinessTags,
    removeBusinessTags
};
