export const NODE_ENV = process.env.NODE_ENV
export const BACKEND_URL = process.env.BACKEND_URL;
export const PAYPAL_ID = process.env.PAYPAL_ID;
export const FRONTEND_ENV = process.env.FRONTEND_ENV;
export const MYACCOUNT_URL = process.env.MYACCOUNT_URL;
export const PURCHASE_URL = process.env.PURCHASE_URL;
export const TIPME_URL = process.env.TIPME_URL;
export const CENTIPENNY_URL = process.env.CENTIPENNY_URL;

export const EXAMPLE_HANDLE = process.env.EXAMPLE_HANDLE;
export const ASSET_GROUP = process.env.ASSET_GROUP;
export const DATA_PATH = `${BACKEND_URL}/data`;
export const PUB_PATH = `${BACKEND_URL}/pub`;
// export const s3_logo_bucket = process.env.S3_LOGO_BUCKET;
export const noLogoImage = `/${process.env.ASSET_GROUP}/assets/images/no_logo.png`;
//export const centipennyLogoSrc = `/${process.env.ASSET_GROUP}/assets/centipenny_logo-main.png`;
export const centipennyLogoSrc = `/${process.env.ASSET_GROUP}/assets/logo_transparent_main.png`;
export const myaccountLogoSrc = `/${process.env.ASSET_GROUP}/assets/centipenny_logo-black.png`;
export const tipMeLogoSrc = `/${process.env.ASSET_GROUP}/assets/logo_social_media.jpg`;

/* keep these the same as in the global settings file */
export const CP_DOMAINS = [
	'centipenny.com',
	'cptip.me',
	'centipennydemo.com',
	'centipennyclientdemo.com',
	'centipennytesting.com',
	'centipennyclienttesting.com',
	'want2read.it',
	'want2do.it',
	'want2download.it',
	'want2get.it',
	'want2play.it'
];
export const minCreditLimit = 100000;
export const maxCreditLimit = 10000000;
export const creditLimitApprovalThreshold = 200000;
export const minCreditPurchaseLimit = 50000;
export const minCashPurchaseLimit = 5;
export const minCashPurchaseLimitText = '$5.00';
export const firstTimeCreditPurchaseAmount = 20000;
export const firstTimeCashPurchaseText = '$2.00';
export const defaultCommissionPercentage = '15%';
export const defaultCommissionPercentageRaw = 0.15;
export const paypalRefundServiceCharge = '15%';
export const paypalRefundServiceChargeRaw = 0.15;
export const minPayoutAmountCps = 2000000;
export const minPayoutAmountRaw = 200;
export const minPayoutAmountText = '$200.00';
export const minTaxReportAmountCps = 6000000;
export const minTaxReportAmountRaw = 600;
export const minTaxReportAmountText = '$600.00';
// export const centipennyBackgroundColor = '#537780';
export const centipennyBackgroundColor = '#112211';


/**
 * Constants for the Tax API
 * NOTE: If you change the tax constants, you must also update the shared constants.
 */

// formats an SSN/ITIN to xxx-xx-xxxx
function formatSSN(ssn) {
	ssn = ssn.replace(/\D/g, '')
	const p1 = ssn.substring(0, 3);
	const p2 = ssn.substring(3, 5);
	const p3 = ssn.substring(5);

	return `${p1}-${p2}-${p3}`;
}

function formatEIN(ein) {
	ein = ein.replace(/\D/g, '')
	const p1 = ein.substring(0, 2);
	const p2 = ein.substring(2);

	return `${p1}-${p2}`;
}
export const taxStructures = {
	businessTaxEntityTypes: ['Individual', 'Sole Proprietorship', 'Corporation', 'Partnership', 'LLC', 'Trust Estate', 'Non-Profit', 'other'],
	businessTaxClassificationTypes: ['Individual', 'Corporation', 'Partnership', 'Trust Estate', 'LLC', 'Non-Profit', 'Non-Citizen'],
	businessTaxIdTypes: ['SSN', 'EIN', 'ITIN'],
	businessTaxIdTypeFunctions: {
		// ***-**-****
		'SSN': {
			validate: function (ssn, errors) {
				if (!ssn.match(/^\d{3}-\d{2}-\d{4}$/)) {
					if (ssn.match(/^\d{9}$/)) {
						return formatSSN(ssn);
					}
					else {
						errors.push('Tax ID, of type SSN, must be in format 123-45-6789 or 123456789')
						return null
					}
				}
				return ssn
			},
			format: formatSSN,
			mask: function (ssn) {
				return '***-**-**' + ssn.substring(9)
			}
		},
		// XX-XXXXXXX 12-3456789
		'EIN': {
			validate: function (ein, errors) {
				if (!ein.match(/^\d{2}-\d{7}$/)) {
					if (ein.match(/^\d{9}$/)) {
						return formatEIN(ein);
					}
					else {
						errors.push('Tax ID, of type EIN, must be in format 12-3456789 or 123456789')
						return null
					}
				}
				return ein
			},
			format: formatEIN,
			mask: function (ein) {
				return '**-*****' + ein.substring(9)
			}
		},
		// 9**-70-****
		'ITIN': {
			validate: function (itin, errors) {
				if (!itin.match(/^9\d{2}-70-\d{4}$/)) {
					if (itin.match(/^\d{9}$/)) {
						return formatSSN(itin);
					}
					else {
						errors.push('Tax ID, of type ITIN, must be in format 9NN-70-NNNN or 9NN70NNNN')
						return null
					}
				}
				return itin
			},
			format: formatSSN,
			mask: function (ITIN) {
				return '9**-70-**' + ITIN.substring(9)
			}
		}
	}
}


export const USStates = [
	{
		"name": "Alabama",
		"abbreviation": "AL"
	},
	{
		"name": "Alaska",
		"abbreviation": "AK"
	},
	{
		"name": "Arizona",
		"abbreviation": "AZ"
	},
	{
		"name": "Arkansas",
		"abbreviation": "AR"
	},
	{
		"name": "California",
		"abbreviation": "CA"
	},
	{
		"name": "Colorado",
		"abbreviation": "CO"
	},
	{
		"name": "Connecticut",
		"abbreviation": "CT"
	},
	{
		"name": "Delaware",
		"abbreviation": "DE"
	},
	{
		"name": "District Of Columbia",
		"abbreviation": "DC"
	},
	{
		"name": "Florida",
		"abbreviation": "FL"
	},
	{
		"name": "Georgia",
		"abbreviation": "GA"
	},
	{
		"name": "Hawaii",
		"abbreviation": "HI"
	},
	{
		"name": "Idaho",
		"abbreviation": "ID"
	},
	{
		"name": "Illinois",
		"abbreviation": "IL"
	},
	{
		"name": "Indiana",
		"abbreviation": "IN"
	},
	{
		"name": "Iowa",
		"abbreviation": "IA"
	},
	{
		"name": "Kansas",
		"abbreviation": "KS"
	},
	{
		"name": "Kentucky",
		"abbreviation": "KY"
	},
	{
		"name": "Louisiana",
		"abbreviation": "LA"
	},
	{
		"name": "Maine",
		"abbreviation": "ME"
	},
	{
		"name": "Maryland",
		"abbreviation": "MD"
	},
	{
		"name": "Massachusetts",
		"abbreviation": "MA"
	},
	{
		"name": "Michigan",
		"abbreviation": "MI"
	},
	{
		"name": "Minnesota",
		"abbreviation": "MN"
	},
	{
		"name": "Mississippi",
		"abbreviation": "MS"
	},
	{
		"name": "Missouri",
		"abbreviation": "MO"
	},
	{
		"name": "Montana",
		"abbreviation": "MT"
	},
	{
		"name": "Nebraska",
		"abbreviation": "NE"
	},
	{
		"name": "Nevada",
		"abbreviation": "NV"
	},
	{
		"name": "New Hampshire",
		"abbreviation": "NH"
	},
	{
		"name": "New Jersey",
		"abbreviation": "NJ"
	},
	{
		"name": "New Mexico",
		"abbreviation": "NM"
	},
	{
		"name": "New York",
		"abbreviation": "NY"
	},
	{
		"name": "North Carolina",
		"abbreviation": "NC"
	},
	{
		"name": "North Dakota",
		"abbreviation": "ND"
	},
	{
		"name": "Ohio",
		"abbreviation": "OH"
	},
	{
		"name": "Oklahoma",
		"abbreviation": "OK"
	},
	{
		"name": "Oregon",
		"abbreviation": "OR"
	},
	{
		"name": "Pennsylvania",
		"abbreviation": "PA"
	},
	{
		"name": "Rhode Island",
		"abbreviation": "RI"
	},
	{
		"name": "South Carolina",
		"abbreviation": "SC"
	},
	{
		"name": "South Dakota",
		"abbreviation": "SD"
	},
	{
		"name": "Tennessee",
		"abbreviation": "TN"
	},
	{
		"name": "Texas",
		"abbreviation": "TX"
	},
	{
		"name": "Utah",
		"abbreviation": "UT"
	},
	{
		"name": "Vermont",
		"abbreviation": "VT"
	},
	{
		"name": "Virginia",
		"abbreviation": "VA"
	},
	{
		"name": "Washington",
		"abbreviation": "WA"
	},
	{
		"name": "West Virginia",
		"abbreviation": "WV"
	},
	{
		"name": "Wisconsin",
		"abbreviation": "WI"
	},
	{
		"name": "Wyoming",
		"abbreviation": "WY"
	}
];
