import { fetch, fetchPost, fetchPut, fetchDelete } from 'utilities/utilities';
import { DATA_PATH } from 'utilities/globals';

export function saveEditPoll (businessId, data, pollId) {
	return fetchPut(`${DATA_PATH}/business/${businessId}/polls/update`, data);
};

export function saveCreatePoll (businessId, data) {
	return fetchPost(`${DATA_PATH}/business/${businessId}/polls/create`, data);
};

export function getEditablePoll (businessId, pollId) {
	return fetch(`${DATA_PATH}/business/${businessId}/polls/edit/${pollId}`);
};

export function getPollsList (businessId, data) {
	return fetchPost(`${DATA_PATH}/business/${businessId}/polls/list`, data);
};

export function cancelPoll (businessId, pollId) {
	return fetchDelete(`${DATA_PATH}/business/${businessId}/polls/${pollId}`);
};
