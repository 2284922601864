import React from "react";
import ReactDOM from "react-dom";
// import "./index.css";
// import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import { Provider } from 'react-redux';
import Routes from './routes'
import store from './store';
import Auth from './components/auth';
import Blank from './blank.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-datepicker/dist/react-datepicker.css';
import './scss/shared/app.scss';
import ConfirmDialog from 'components/confirmDialog/index.js'
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { PAYPAL_ID } from 'utilities/globals';


switch (process.env.FRONTEND_ENV) {
  case 'myaccount': {
    await import('./scss/MyAccount/app.scss')
    break;
  }
  case 'tipme': {
    await import('./scss/TipMe/app.scss')
    break;
  }
  case 'centipenny': {
    await import('./scss/CentiPenny/app.scss')
    break;
  }
  /*case 'purchase': {
    console.log('purchase')
    await import('./scss/Purchases/app.scss')
    break;
  } */
  default: {
    throw new Error('No FRONTEND_ENV defined')
  }
}

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <Auth>
        
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        {/* Same as */}
        <PayPalScriptProvider options={{ "client-id": PAYPAL_ID }}>
          <React.Suspense fallback={<Blank/>}>
            <Routes />
          </React.Suspense>
        </PayPalScriptProvider>
        <ConfirmDialog/>
      </Auth>
    </Provider>
  </BrowserRouter>,
  document.getElementById("root")
);
