import { fetch, fetchPost } from 'utilities/utilities';
import { DATA_PATH } from 'utilities/globals';

export function getTaxDataRedacted (businessId) {
	return fetch(`${DATA_PATH}/business/${businessId}/taxes`);
};

export function getTaxDataFull(businessId) {
    return fetch(`${DATA_PATH}/business/${businessId}/taxes/full`);
};

export function saveTaxData(businessId, data) {
    return fetchPost(`${DATA_PATH}/business/${businessId}/taxes`, data);
};
