import { fetch, buildQuery } from 'utilities/utilities';
import { DATA_PATH } from 'utilities/globals';
import moment from 'moment';

export function getBusinessReportToday (businessId) {
	return fetch(`${DATA_PATH}/business/${businessId}/reports/today`);
};

export function getReportLifetime ({
	businessId,
	reportType
}) {
	const query = buildQuery({reportType}, true);
	return fetch(`${DATA_PATH}/business/${businessId}/reports/lifetime${query}`);
};

export function getReportMonth ({
	businessId,
	month,
	reportType
}) {
	month = moment(month).format('YYYY-MM-DD');
	const query = buildQuery({month, reportType}, true);
	return fetch(`${DATA_PATH}/business/${businessId}/reports/month${query}`);
};

export function getReportTypes (businessId) {
	return fetch(`${DATA_PATH}/business/${businessId}/reports/types`);
};
