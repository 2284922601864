import axios from 'axios';
import jwtDecode from 'jwt-decode';
import { BACKEND_URL } from 'utilities/globals';

class AuthService {
	init () {
		this.setInterceptors();
		this.handleAuthentication();
	}

	setInterceptors = () => {
		axios.interceptors.response.use(response => {
			return response;
		}, err => {
			return new Promise((resolve, reject) => {
				if (err.response.status === 401 && err.config && !err.config.__isRetryRequest) {
					// if you ever get an unauthorized response, logout the user
					this.setSession(null);
				}
				throw err;
			});
		});
	};

	handleAuthentication = () => {
		const { token, session } = this.getAccessToken();
        if (token) {
            if (this.isAuthTokenValid(token)) {
                return new Promise((resolve, reject) => {
                    this.setSession(token, !session);
                });
            }
            else {
                this.setSession(null);
            }
        }

	};

	/*
		credentials = {email, password, keepLoggedIn}
	*/
	signInWithEmailAndPassword = (credentials) => {
		this.setSession(null);
		return new Promise((resolve, reject) => {
			axios.post(`${BACKEND_URL}/auth/login`, credentials)
				.then(response => {
					if (response.data.user) {
						this.setSession(response.data.access_token, credentials.keepLoggedIn);
						const { user } = response.data;
						user.isLoggedIn = true;
						resolve(user);
					}
					else {
						reject(response.data.error);
					}
				})
				.catch(error => {
					// console.log('LOGIN ERROR: ', error.message);
					this.logout();
					reject('Failed to login.');
				});
		});
	};

	signInWithToken = async () => {
		const { token } = this.getAccessToken();
		if (token) {
			const response = await axios.get(`${BACKEND_URL}/auth/access-token`, {
				access_token: token
			});
			if (response.data.user) {
				const { user } = response.data;
				user.isLoggedIn = true;
				return user;
			}
			else {
				this.setSession(null);
				return null;
			}
		}
		return null;
	};

	doPostLogin = () => {
		/* getUserMenuData()
		getUserBusinessMenuData()
		loadPermissionsList() */
	};

	updateUserData = (user) => {
		return axios.post(`${BACKEND_URL}/auth/user/update`, {
			user: user
		});
	};

	setSession = (access_token, keepLoggedIn) => {
		if (access_token) {
			if (keepLoggedIn) {
				localStorage.setItem('jwt_access_token', access_token);
			}
			else {
				sessionStorage.setItem('jwt_access_token', access_token);
			}
			axios.defaults.headers.common['Authorization'] = 'Bearer ' + access_token;
		}
		else {
			localStorage.removeItem('jwt_access_token');
			sessionStorage.removeItem('jwt_access_token');
			delete axios.defaults.headers.common['Authorization'];
		}
	};

	logout = () => {
		this.setSession(null);
	};

	isAuthTokenValid = access_token => {
		if (!access_token) {
			return false;
		}
		const decoded = jwtDecode(access_token);
		const currentTime = Date.now() / 1000;
		if (decoded.exp < currentTime) {
			// console.warn('access token expired');
			return false;
		}
		else {
			return true;
		}
	};

	getAccessToken = () => {
		let token = localStorage.getItem('jwt_access_token');
		let session = false;
		if (!token) {
			token = sessionStorage.getItem('jwt_access_token');
			session = true;
		}
		return {
			token,
			session
		};
	};
}

const instance = new AuthService();

export default instance;
