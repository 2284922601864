import React, { useEffect, useState } from 'react';
import { Button, Modal} from 'react-bootstrap';
import {useDispatch, useSelector} from 'react-redux';
import { closeConfirmDialog } from 'store/slices/components';

function ConfirmDialog () {
	const dispatch = useDispatch();
	const open = useSelector(({ components }) => components.confirmDialog.open);
	const options = useSelector(({ components }) => components.confirmDialog.options);
	const [titleClass, setTitleClass] = useState()
	const [text, setText] = useState('');
	useEffect(() => {
		if (open) {
			if (options.type) {
				switch (options.type) {
					case 'success': setTitleClass('success'); break;
					case 'warning': setTitleClass('error'); break;
					default: setTitleClass('error'); break;
				}
			}
			setText(options.text);
		}
	}, [open, options]);

	function handleCancel () {
		options.onClose(false);
		dispatch(closeConfirmDialog());
	};

	function handleOk () {
		options.onClose(true);
		dispatch(closeConfirmDialog());
	};

	return (
		<Modal
			aria-labelledby="confirmation-dialog-title"
			show={open}
		>
			<Modal.Header className={titleClass}>
				<Modal.Title>Confirm to continue:</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<p>{text}</p>
			</Modal.Body>
			<Modal.Footer>
				<Button autoFocus onClick={handleCancel} variant="secondary">
					Cancel
				</Button>
				<Button onClick={handleOk} variant="primary">
					Ok
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default ConfirmDialog;
