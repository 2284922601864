import { createSlice } from '@reduxjs/toolkit'

export const data = createSlice({
	name: 'data',
	initialState: {
		userMenu: [],
		business: null,
		permissions: [],
		referrerCode: null,
		contactTypes: [],
		loading: false
	},
	reducers: {
		saveReferrer: (state, action) => {
			state.referrerCode = action.payload.referrerCode;
			return state;
		},
		saveBusiness: (state, action) => {
			state.business = action.payload.business;
			return state;
		},
		loadingStart: (state) => {
			state.loading = true;
			return state;
		},
		loadingEnd: (state) => {
			state.loading = false;
			return state;
		},
		saveUserMenu: (state, action) => {
			state.userMenu = action.payload.menu;
			return state;
		},
		savePermissions: (state, action) => {
			state.permissions = action.payload.permissions;
			return state;
		},
		saveContactTypes: (state, action) => {
			state.contactTypes = action.payload.contactTypes;
			return state;
		},
	}
})

export const {
	saveCountries,
	saveLanguages,
	saveReferrer,
	saveBusiness,
	loadingStart,
	loadingEnd,
	saveUserMenu,
	savePermissions,
	saveContactTypes
} = data.actions

export default data.reducer
