import React from 'react';
// import { userActions } from 'store/actions.js';
import authService from 'services/auth';
import {NODE_ENV} from 'utilities/globals';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { loadPostLogin } from 'dataLoaders';

function Auth (props) {
	const dispatch = useDispatch()
	authService.init();
	authService.signInWithToken()
		.then(user => {
			if ((user._id < 1000000 && user.userType === 'customer') || (user._id >= 1000000 && user.userType === 'employee')) {
				toast.error('Invalid User')
				props.logout();
				return;
			}
			user.isLoggedIn = true;
			dispatch(loadPostLogin(user))
			if (NODE_ENV.toLowerCase() === 'development') {
				console.log('USER: ', user);
			}
		})
		.catch(error => {
			if (NODE_ENV.toLowerCase() === 'development') {
				toast.error({message: error});
			}
		});

	return (
		<React.Fragment children={props.children}/>
	)
};


export default Auth;
