import * as React from "react";
let Routes

switch (process.env.FRONTEND_ENV) {
    case 'myaccount': {
        Routes = React.lazy(() => import('./myaccount.routes.js'));
        break;
    }
    case 'tipme': {
        Routes = React.lazy(() => import('./tipme.routes.js'));
        break;
    }
    case 'centipenny': {
        Routes = React.lazy(() => import('./centipenny.routes.js'));
        break;
    }
    case 'purchase': {
        Routes = React.lazy(() => import('./purchase.routes.js'));
        break;
    }
    default: {
        throw new Error('No FRONTEND_ENV defined')
    }
}


export default Routes
