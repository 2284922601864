import { fetch, fetchPost } from 'utilities/utilities';
import { DATA_PATH } from 'utilities/globals';

export function getBusinessEmployeesList (businessId) {
	return fetch(`${DATA_PATH}/business/${businessId}/employees/list`);
};

export function getBusinessEmployeeInvitesList (businessId) {
	return fetch(`${DATA_PATH}/business/${businessId}/employees/invite/pending`);
};

export function submitUpdateEmployee (businessId, employee) {
	return fetchPost(`${DATA_PATH}/business/${businessId}/employees/update`, employee);
};

export function submitDeactivateEmployee (businessId, employeePublicId) {
	return fetchPost(`${DATA_PATH}/business/${businessId}/employees/deactivate/${employeePublicId}`);
};

export function submitEmployeeInvites (businessId, emails) {
	return fetchPost(`${DATA_PATH}/business/${businessId}/employees/invite`, emails);
};

export function submitResendEmployeeInvite (businessId, inviteId) {
	return fetch(`${DATA_PATH}/business/${businessId}/employees/invite/resend/${inviteId}`);
};

export function submitExpireEmployeeInvite (businessId, inviteId) {
	return fetch(`${DATA_PATH}/business/${businessId}/employees/invite/expire/${inviteId}`);
};

export function submitEmployeeInviteAcceptance (inviteId) {
	return fetch(`${DATA_PATH}/business/invite/accept/${inviteId}`);
};

export function submitEmployeeInviteDecline (inviteId) {
	return fetch(`${DATA_PATH}/business/invite/decline/${inviteId}`);
};
