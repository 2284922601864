import { dataActions } from "store/actions";
import { getPermissionsList, getCountryList, getLanguageList } from 'services/general';
import { getContactTypes, getBusinessDetails } from 'services/businesses';
import { toast } from 'react-toastify';

// gets business details for use on Tip Me
// businessId = business.publicId or business.handle
export function getBusinessPublicDetails (businessId) {
	return async (dispatch) => {
		const results = await getBusinessDetails(businessId);
        if (results.success) {
            dispatch(dataActions.saveBusiness({
				business: results.values
			}));
        } else {
            toast.error(results.message);
        }
	};
};

export function loadPermissionsList (load) {
	return async (dispatch) => {
        const permissions = await getPermissionsList()
		dispatch(dataActions.savePermissions({
			permissions
		}));
	};
};

export function loadContactTypes () {
	return async (dispatch) => {
		const results = await getContactTypes();
		if (results.success) {
			dispatch(dataActions.saveContactTypes({
				contactTypes: results.values
			}));
		}
		else {
			toast.error(results.message);
		}
	};
};
