import { createSlice } from '@reduxjs/toolkit'

export const components = createSlice({
	name: 'components',
	initialState: {
		confirmDialog: {
			open: false,
			options: null
		},
		creditLimitDialog: {
			open: false
		},
		autopayDialog: {
			open: false
		},
		loginModal: {
			open: false
		},
		buyCreditsModal: {
			open: false
		}
	},
	reducers: {
		openConfirmDialog: (state, action) => {
			state.confirmDialog = {
				open: true,
				options: action.payload.options
			};
			return state;
		},
		closeConfirmDialog: (state, action) => {
			state.confirmDialog = {
				open: false,
				options: null
			};
			return state;
		},
		openCreditLimitDialog: (state, action) => {
			state.creditLimitDialog = {
				open: true
			};
			return state;
		},
		closeCreditLimitDialog: (state, action) => {
			state.creditLimitDialog = {
				open: false
			};
			return state;
		},
		openLoginModal:  (state, action) => {
			state.loginModal = {
				open: true
			};
			return state;
		},
		closeLoginModal:  (state, action) => {
			state.loginModal = {
				open: false
			};
			return state;
		},
		openBuyCreditsModal:  (state, action) => {
			state.buyCreditsModal = {
				open: true
			};
			return state;
		},
		closeBuyCreditsModal:  (state, action) => {
			state.buyCreditsModal = {
				open: false
			};
			return state;
		}
	}
});

export const {
	openConfirmDialog,
	closeConfirmDialog,
	openCreditLimitDialog,
	closeCreditLimitDialog,
	openLoginModal,
	closeLoginModal,
	openBuyCreditsModal,
	closeBuyCreditsModal
} = components.actions

export default components.reducer
